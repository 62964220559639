var config_global = false;
var setMeasurement, study_validated = false;
var userProjects = [];
var analysis_ready = false;
export default class Store {
    static init() {
        this.securityLog = [];
        this.userLog = [];
        this.images = this.images? this.images : false;
        this.images_to_load = this.images_to_load? this.images_to_load : false;
        this.images_with_comments = [];
        this.config_image_types = {};
        this.config_measurements = {};
        this.config_image_quality = {};
        this.current_cloud = "";
        this.config_APIs = {};
        
        this.user_token = localStorage.getItem('token');
        this.user_family_names = localStorage.getItem('family_names');
        this.user_name = localStorage.getItem('name');
        this.user_email = localStorage.getItem('email');
        this.user_type = localStorage.getItem('user_type');
        this.study_validated = 0;
    }

    // USER RELATED ACTIONS
    static setUser(token, name, family_names, email) {
        this.user_token = token;
        this.user_family_names = family_names;
        this.user_name = name;
        this.user_email = email;
    }
    static setUserType(type) {
        this.user_type = type;
    }
    static getUser() {
        return {
            user_type: this.user_type,
            user_token: this.user_token,
            user_name: this.user_name,
            user_family_names: this.user_family_names,
            user_email: this.user_email
        }
    }
    static removeUser() {
        this.user_token = null;
        this.user_family_names = null;
        this.user_name = null;
        this.user_email = null;
        this.user_type = null;
    }
    // CONFIG APIS
    static setConfigAPIs(config) {
        this.config_APIs = config;
    }
    static getConfigAPIs() {
        return this.config_APIs;
    }
    static setCurrentCloud(cloud) {
        this.current_cloud = cloud;
    }
    static getCurrentCloud() {
        return this.current_cloud;
    }
    // CONFIG IMAGE TYPES
    static setConfigImageTypes(config) {
        this.config_image_types = config;
    }
    static getConfigImageTypes() {
        return this.config_image_types;
    } 
    // CONFIG MEASUREMENTS
    static setConfigMeasurements(config) {
        this.config_measurements = config;
    }
    static getConfigMeasurements() {
        return this.config_measurements;
    } 
    // CONFIG IMAGE QUALITY
    static setConfigImageQuality(config) {
        this.config_image_quality = config;
    }
    static getConfigImageQuality() {
        return this.config_image_quality;
    } 
    // CONFIG CALIPER
    static setConfigCaliper(config) {
        this.config_caliper = config;
    }
    static getConfigCaliper() {
        return this.config_caliper;
    } 

    // STUDIES PAGE RELATED ACTIONS
    static setCurrentPage(page) {
        this.current_page = page;
    }
    static getCurrentPage() {
        return this.current_page;
    }
    static setCurrentSorting(sort) {
        this.current_sorting = sort;
    }
    static getCurrentSorting() {
        return this.current_sorting;
    }
    static setCurrentSearch(search) {
        this.current_search = search;
    }
    static getCurrentSearch() {
        return this.current_search;
    }
    // PROJECTS
    static setCurrentProject(project) {
        this.currentproject = project;
    }
    static setCurrentProjectObj(project) {
        this.currentprojectobj = project;
    }
    static setCurrentProjectStudies(studies) {
        this.currentprojectstudies = studies;
    }
    static getCurrentProject() {
        return this.currentproject;
    }
    static getCurrentProjectObj() {
        return this.currentprojectobj;
    }
    static getCurrentProjectStudies() {
        return this.currentprojectstudies;
    }
    // STUDIES
    static setStudyId(study_id) {
        this.study_id = study_id;
    }
    static getStudyId() {
        return this.study_id;
    }
    static setStudyDate(study_date) {
        this.study_date = study_date;
    }
    static getStudyDate() {
        return this.study_date;
    }
    static setPatientId(patient_id) {
        this.patient_id = patient_id;
    }
    static getPatientId() {
        return this.patient_id;
    }
    static setPatientIdShow(patient_id_show) {
        this.patient_id_show = patient_id_show;
    }
    static getPatientIdShow() {
        return this.patient_id_show;
    }
    static setDataType(data_type){
        this.data_type = data_type;
    }
    static getDataType(){
        return this.data_type;
    }
    // STUDY ANALYSIS
    static setStudyState(study_state){
        this.study_state = study_state;
    }
    static getStudyState(){
        return this.study_state;
    }

    // IMAGES RELATED ACTIONS
    static setStudyImages(images){
        this.images = images;
    }
    static getStudyImages(){
        return this.images;
    }
    static setOriginalAnalysisImages(images){
        this.original_analysis_images = images;
    }
    static getOriginalAnalysisImages(){
        return this.original_analysis_images;
    }
    static setImagesToLoad(images){
        this.images_to_load = images;
    }
    static getImagesToLoad(){
        return this.images_to_load;
    }

    // REVIEWS
    static setEditor(editor) {
        this.editor = editor;
    }
    static getEditor() {
        return this.editor;
    }
    static setValidated(validated) {
        this.validated = validated;
    }
    static getValidated() {
        return this.validated;
    }
    static setHideComplete(hide) {
        this.hide_complete = hide;
    }
    static getHideComplete() {
        return this.hide_complete;
    }

    // TOOLS VARIABLES
    static setCurrentAction(current_action) {
        this.current_action = current_action;
    }
    static getCurrentAction() {
        return this.current_action;
    }

}