import { useStyles } from './db_styles';
import Switch from '@material-ui/core/Switch';

import { HelperStudies } from '../../../helpers/helper_studies/helper_studies';

export function DatabaseQuery(props) {
    const classes = useStyles();
    const performQuery = () => {
        let query_cont = document.getElementById("query");
        props.onactiontoperform("GENERAL",{ action: "PERFORM_QUERY", value: query_cont.value })
    }
    const renderSwitch = () => {
        if (["review","studies"].includes(props.from)) {
            return (
                <div className={classes.switchText}>
                    Hide completed studies
                    <Switch
                        checked={props.hide_completed}
                        onClick={props.on_change_hide}
                    />
                </div>
            );
        }
    }
    return (
        <div style={{ display: "grid", gridTemplateColumns: "320px 1fr 290px", marginTop: "11px" }}>
            <div className={classes.searchMethods}>
                <input type="radio" checked={props.search_method==="contain"} onChange={() => props.on_change_search_method("contain")} /> Contain
                <input type="radio" checked={props.search_method==="match"} onChange={() => props.on_change_search_method("match")} /> Match
                <input type="radio" checked={props.search_method==="start_with"} onChange={() => props.on_change_search_method("start_with")} /> Start with
            </div>
            <input className={classes.queryBox} name="query" form="usrform" id="query"
                onChange={() => performQuery()}
                style={{ border: "2px solid #f44336" }}
                placeholder=" Search patient by ID..."
                value={HelperStudies.getCurrentSearch()? HelperStudies.getCurrentSearch().value : ""}
            >
            </input>
            {renderSwitch()}
        </div>
    )
}