import { HelperConfig } from './../helpers/helper_config/helper_config';
import { HelperStudies } from './../helpers/helper_studies/helper_studies';
import { HelperUser } from './../helpers/helper_user/helper_user';
import { codeString } from './../modules/string_module';
import { isEmptyArray } from './../modules/array_module';

export const generalActions = (action, myC) => {
    
    if (action.action === "GO_TO_PAGE") {
        if (["Ground-truth generator","Editor"].includes(HelperUser.getUserType()) && action.value.from !== "studies") {
            HelperStudies.saveStudy();
        }
        var url = defineUrlParams(action);
        if (url !== "") {
            setUrl(url, myC);
            if (action.type === "refresh") {
                window.location.reload();
            } 
        }

    } else if (action.action === "LOGOUT") {
        HelperUser.removeUser();
        setUrl("login", myC);

    } else if (action.action === "CHANGE_PROJECT") {
        if (action.change || !HelperStudies.getCurrentPage()) {
            HelperStudies.setCurrentPage(0);
        }
        HelperStudies.setCurrentProject(action.value).then(()=>{
            myC.setState({
                currentproject: action.value,
                studies: false
            });
            setUrl(action.from + "?project=" + action.value, myC);
            downloadProjectStudies(action.value, myC).then(() => {
                let current_search = HelperStudies.getCurrentSearch();
                generalActions({ action: "PERFORM_QUERY", value: current_search.value }, myC);
            });
        });

    } else if (action.action === "GET_PROJECT_STUDIES") {
        downloadProjectStudies(action.value, myC);

    } else if (action.action === "CHANGE_SEARCH_METHOD") {
        let current_search = HelperStudies.getCurrentSearch();
        HelperStudies.setCurrentSearch({ method: action.value, value: current_search.value});
        myC.setState({ search_method: action.value })
        if (current_search.value !== "") {
            generalActions({ action: "PERFORM_QUERY", value: current_search.value }, myC);
        }

    } else if (action.action === "PERFORM_QUERY") {
        let current_search = HelperStudies.getCurrentSearch();
        HelperStudies.setCurrentSearch({ method: current_search.method, value: action.value});
        let query_studies = myC.state.studies;
        if (action.value !== "") {
            if (current_search.method === "start_with") {
                query_studies = myC.state.studies.filter(s => s.patient_show.toLowerCase().startsWith(action.value.toLowerCase()));
            } else if (current_search.method === "match") {
                query_studies = myC.state.studies.filter(s => s.patient_show.toLowerCase() === action.value.toLowerCase());
            } else {
                query_studies = myC.state.studies.filter(s => s.patient_show.toLowerCase().includes(action.value.toLowerCase()));
            }
            HelperStudies.setCurrentPage(0);    
        }
        let sort = HelperStudies.getCurrentSorting();
        generalActions({ action: "SORT_BY", value: sort.by, direction: sort.direction, studies: query_studies }, myC);

    } else if (action.action === "SORT_BY") {
        // TO-DO: How to sort STATUS
        let studies_array = action.studies? action.studies : myC.state.studies_to_show;
        let sort = HelperStudies.getCurrentSorting();
        let direction = action.direction? action.direction : sort?.by === action.value? (sort.direction === "asc"? "desc" : "asc") : "asc";
        let studies_w_value = studies_array.filter(s => s[action.value] && !isEmptyArray(s[action.value]));
        let studies_wo_value = studies_array.filter(s => !s[action.value] || isEmptyArray(s[action.value]));
        let sorted_studies = [];
        if (studies_w_value.length > 0) {
            const format = (a) => a.replaceAll(" ","").replaceAll("-","").replaceAll("_","");
            const comparator = (a,b) => format(a).localeCompare(format(b), undefined, { numeric: true, sensitivity: "base" });
            sorted_studies = studies_w_value.sort((a,b) => { return direction === "asc"? comparator(a[action.value],b[action.value]) : comparator(b[action.value],a[action.value]) });
        }
        sorted_studies = sorted_studies.concat(studies_wo_value);
        HelperStudies.setCurrentSorting({ by: action.value, direction });
        myC.setState({
            studies_to_show: sorted_studies
        });

    } else if (action.action === "DRAG-ELEMENT") {
        dragElement(action.value);
    }
}

function downloadProjectStudies(currentproject, myC) {
    return HelperStudies.get(currentproject).then((studies) => {
        console.log("STUDIES", studies.length)
        if ("error" in studies) {
            if (studies.error === "UNAUTHORIZED") {
                HelperUser.removeUser();
                setUrl("login", myC);
            }
        } else {
            // console.log(studies, currentproject)
            myC.setState({
                studies: studies,
                studies_to_show: studies,
                currentproject: currentproject
            });
        }
    });
}

function defineUrlParams(action) {
    let url = "";
    let params = "";
    if (action.value.page === "studies"  || action.value.page === "review") {
        url = action.value.page;
        params = HelperStudies.getCurrentProject()? "project=" + HelperStudies.getCurrentProject() : "";
    } else if (action.value.page === "user-page") {
        HelperUser.setUserType(null);
        url = "user-page";
    } else if (action.value.page === "export"){
        url = "export";
    } else {
        let id_study = action.value.id_study? action.value.id_study : HelperStudies.getStudyId();
        let date = action.value.date? action.value.date : HelperStudies.getStudyDate();
        let id_patient = action.value.id_patient? action.value.id_patient : HelperStudies.getPatientId();
        let id_patient_show = action.value.id_patient_show? action.value.id_patient_show : HelperStudies.getPatientIdShow();
        let type = action.value.type? action.value.type : HelperStudies.getDataType();
        let project = action.value.project? action.value.project : HelperStudies.getCurrentProject();
        let editor = action.value.editor? action.value.editor : HelperStudies.getEditor();
        if (action.value.from && action.value.from === "studies") {
            url = action.value.page.includes("segmented")? "segmentation" : HelperConfig.getTabUrl({ status: action.value.page });
        } else {
            url = HelperConfig.getTabUrl({ name: action.value.page });
        }
        params = "study_id=" + id_study + "&study_date=" + date + "&patient_id=" + id_patient + "&patient_id_show=" + id_patient_show + "&data_type=" + type + "&project=" + project;
        if (action.value.image) {
            params += "&image=" + action.value.image;
        }
        if (action.value.from && action.value.from === "studies") {
            params += "&studies=true";
        }
        if (editor) {
            params += "&editor=" + editor;
        }
        if (action.value.db_status) {
            params += "&validated=" + String(action.value.db_status.validated);
        }
        // if(action.value)
        params = codeString(params,"encode");
    }

    return url+"?"+params;
}

function setUrl(url, myC) {
    if (url !== false) {
        let browserHistory = myC.props.history;
        browserHistory.push("/" + url);
    } else {
        alert("URL not compatible");
    }
}

function dragElement(element) {
    var pos1 = 0,
        pos2 = 0,
        pos3 = 0,
        pos4 = 0;

    // otherwise, move the DIV from anywhere inside the DIV:
    if (element) {
        element.onmousedown = dragMouseDown;

    }

    function dragMouseDown(e) {
        e = e || window.event;
        e.preventDefault();
        // get the mouse cursor position at startup:
        pos3 = e.clientX;
        pos4 = e.clientY;
        document.onmouseup = closeDragElement;
        // call a function whenever the cursor moves:
        document.onmousemove = elementDrag;
    }

    function elementDrag(e) {
        e = e || window.event;
        e.preventDefault();
        // calculate the new cursor position:
        pos1 = pos3 - e.clientX;
        pos2 = pos4 - e.clientY;
        pos3 = e.clientX;
        pos4 = e.clientY;
        // set the element's new position:
        element.style.top = (element.offsetTop - pos2) + "px";
        element.style.left = (element.offsetLeft - pos1) + "px";
    }

    function closeDragElement() {
        // stop moving when mouse button is released:
        document.onmouseup = null;
        document.onmousemove = null;
    }
}