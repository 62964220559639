import React, { Component } from 'react';

//components
import { HeaderComponent } from './../../components/header_component/header_component';
import { LoadingComponent } from './../../components/loading_component/loading_component';
import { DatabaseComponent } from '../../components/database_component/db_component';

// Helpers
import { HelperUser } from './../../../helpers/helper_user/helper_user';
import { HelperConfig } from './../../../helpers/helper_config/helper_config';
import { HelperStudies } from '../../../helpers/helper_studies/helper_studies';

// Actions file
import { onActionToPerform } from './../../../actions/actions';

export class StudiesPage extends Component {
    constructor() {
        super();
        this.state = {
            site: "",
            current_study_analysis: false,
            export_studies: false,
            currentproject: "",
            tab: "list",
            projects: [],
            hide_completed: false
        }
        this.user = {};
    }

    componentDidMount() {
        if (!HelperUser.validateUserAuthentication()) {
            this.onActionToPerform("GENERAL", { action: "LOGOUT", value: "" });
        } else {
            HelperConfig.init().then(() => {
                HelperStudies.resetStudyInfo();
                HelperUser.getUserProjects().then(projects => {
                    if (projects && projects.length > 0) {
                        let project_promises = [];
                        projects.map((project) => {
                            project_promises.push(HelperStudies.getProject(project));
                        });
                        Promise.all(project_promises).then((projects_obj) => {
                            projects_obj = projects_obj.filter(pr => pr.cloud_system === HelperConfig.getCurrentCloud());
                            let currentproject = HelperStudies.setInitialCurrentProject(projects_obj.map(pr => pr._id));
                            if (!HelperStudies.getCurrentSorting()) {
                                HelperStudies.setCurrentSorting({ by: "date", direction: "desc" });
                            }
                            if (!HelperStudies.getCurrentSearch()) {
                                HelperStudies.setCurrentSearch({ method: "contain", value: "" });
                            }
                            this.onActionToPerform("GENERAL", { action: "CHANGE_PROJECT", value: currentproject, from: "studies" });
                            this.setState({
                                projects: projects_obj,
                                search_method: HelperStudies.getCurrentSearch().method,
                                hide_completed: HelperStudies.getHideComplete()? true : false
                            });                      
                        });
                    }
                })
            });
        }
        this.initView();
    }

    initView() {
        this.user = HelperUser.getUser();
        let site = window.location.pathname;
        if (site.indexOf("/") !== -1) {
            site = site.replace("/", "");
        }
        this.setState({
            site: site,
        });
    }

    handleTabChange(tab) {
        this.setState({ tab });
    }

    onActionToPerform(actionType, action) {
        onActionToPerform(actionType, action, this);
    }

    renderStudies() {
        if (this.state.studies_to_show) {
            let studies_to_analize = this.state.studies_to_show;
            if (this.state.hide_completed) {
                studies_to_analize = studies_to_analize.filter(study => !(study.validated_by.length > 0 && study.validated_by[0].includes("reviewer")));
                // "HARDCODED" FOR PRISMA PROJECT
                const project = HelperStudies.getCurrentProjectObj();
                if (project._id === "fedoc-arc") {
                    studies_to_analize = studies_to_analize.filter(study => study.study_type && study.study_type !== "Enrollment");
                    studies_to_analize = studies_to_analize.filter(study => !(study.status.selected && study.status.qc));
                }
            }
            let page = HelperStudies.getCurrentPage();
            let sort = HelperStudies.getCurrentSorting();
            return (
                <DatabaseComponent
                    site={this.state.site}
                    projects={this.state.projects}
                    onactiontoperform={this.onActionToPerform.bind(this)}
                    currentProject={this.state.currentproject}
                    items={studies_to_analize}
                    initial_page={page? page : 0}
                    sort_by={sort.by}
                    sort_direction={sort.direction}
                    studies_tab={this.state.tab}
                    from={"studies"}
                    page={"preview"}
                    hide_completed={this.state.hide_completed}
                    on_change_hide={() => { HelperStudies.setHideComplete(!this.state.hide_completed); this.setState({ hide_completed: !this.state.hide_completed }); }}
                    search_method={this.state.search_method}
                    on_change_search_method={(method) => this.onActionToPerform("GENERAL", { action: "CHANGE_SEARCH_METHOD", value: method })}
                />
            )
        } else {
            return (
                <LoadingComponent
                    site={"studies"}
                />
            )
        }
    }


    render() {
        return (
            <div className="grid-frame vertical">
                <HeaderComponent
                    mode={"studies"}
                    studies_tab={this.state.tab}
                    studies_tab_change={this.handleTabChange.bind(this)}
                    user={this.user}
                    on_action_to_perform={this.onActionToPerform.bind(this)}
                />
                {this.renderStudies()}
            </div>
        );
    }
}