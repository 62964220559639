import React from 'react';
import { useStyles } from './../toolbox_styles';

export const ToolboxUserIcons = (props) => {
    const classes = useStyles();

    if (props.icon === "user") {
        return (
            <div className={"grid-block shrink " + classes.toolboxUserIcons}>
                <svg viewBox="0 0 16 16">
                    <path d="M8 2c1.4 0 2.5 1.1 2.5 2.5S9.4 7 8 7 5.5 5.9 5.5 4.5 6.6 2 8 2m0-1C6.1 1 4.5 2.6 4.5 4.5S6.1 8 8 8s3.5-1.6 3.5-3.5S9.9 1 8 1zm5 14h-1v-2.5c0-1.4-1.1-2.5-2.5-2.5h-3C5.1 10 4 11.1 4 12.5V15H3v-2.5C3 10.6 4.6 9 6.5 9h3c1.9 0 3.5 1.6 3.5 3.5V15z"></path>
                </svg>
            </div>
        )
    } else if (props.icon === "email") {
        return (
            <div className={"grid-block shrink " + classes.toolboxUserIcons}>
                <svg viewBox="0 0 16 16">
                    <path d="M14 3H2c-.6 0-1 .4-1 1v8c0 .6.4 1 1 1h12c.6 0 1-.4 1-1V4c0-.6-.4-1-1-1zm-1.1 1L8 7.4 3.1 4h9.8zM2 12V4.5l5.7 4c.2.1.4.1.6 0l5.7-4V12H2z"></path>
                </svg>
            </div>
        )
    } else if (props.icon === "roles") {
        return (
            <div className={"grid-block shrink " + classes.toolboxUserIcons}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#2d2e2e" fill="none" strokeLinecap="round" strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <circle cx="9" cy="7" r="4" />
                    <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                    <path d="M16 3.13a4 4 0 0 1 0 7.75" />
                    <path d="M21 21v-2a4 4 0 0 0 -3 -3.85" />
                </svg>
            </div>
        )
    } else if (props.icon === "book") {
        return (
            <div className={"grid-block shrink " + classes.toolboxUserIcons}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#2d2e2e" fill="none" strokeLinecap="round" strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                    <path d="M3 19a9 9 0 0 1 9 0a9 9 0 0 1 9 0" />
                    <path d="M3 6a9 9 0 0 1 9 0a9 9 0 0 1 9 0" />
                    <line x1="3" y1="6" x2="3" y2="19" />
                    <line x1="12" y1="6" x2="12" y2="19" />
                    <line x1="21" y1="6" x2="21" y2="19" />
                </svg>
            </div>
        )
    } else if (props.icon === "document") {
        return (
            <div className={"grid-block shrink " + classes.toolboxUserIcons}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" stroke="none" fill="#2d2e2e">
                    <path d="M11.362 2c4.156 0 2.638 6 2.638 6s6-1.65 6 2.457v11.543h-16v-20h7.362zm.827-2h-10.189v24h20v-14.386c0-2.391-6.648-9.614-9.811-9.614zm4.811 13h-10v-1h10v1zm0 2h-10v1h10v-1zm-3 3h-7v1h7v-1z"/>
                </svg>
            </div>
        )
    } else if (props.icon === "list") {
        return (
            <div className={"grid-block shrink " + classes.toolboxUserIcons}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" strokeWidth="2" stroke="white" fill="none" strokeLinecap="round" strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                    <circle cx="15" cy="15" r="4" />
                    <path d="M18.5 18.5l2.5 2.5" />
                    <path d="M4 6h16" />
                    <path d="M4 12h4" />
                    <path d="M4 18h4" />
                </svg>
            </div>
        )
    } else if (props.icon === "stats") {
        return (
            <div className={"grid-block shrink " + classes.toolboxUserIcons}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" strokeWidth="1.5" stroke="white" fill="none" strokeLinecap="round" strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                    <rect x="3" y="12" width="6" height="8" rx="1" />
                    <rect x="9" y="8" width="6" height="12" rx="1" />
                    <rect x="15" y="4" width="6" height="16" rx="1" />
                    <line x1="4" y1="20" x2="18" y2="20" />
                </svg>
            </div>
        )
    } else if (props.icon === "download") {
        return (
            <div className={"grid-block shrink " + classes.toolboxUserIcons}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" strokeWidth="2" stroke="white" fill="none" strokeLinecap="round" strokeLinejoin="round">
                    <path d="M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2" />
                    <path d="M7 11l5 5l5 -5" />
                    <path d="M12 4l0 12" />
                </svg>
            </div>
        )
    } else if (props.icon === "logout") {
        return (
            <div className={"grid-block shrink " + classes.toolboxUserIcons}>
                <svg viewBox="0 0 32 32">
                    <path d="M28 22H11.41L30 3.41 28.59 2l-2 2H4a2 2 0 0 0-2 2v16h2V6h20.59L2 28.59 3.41 30l6-6H12v4H8v2h16v-2h-4v-4h8a2 2 0 0 0 2-2V9h-2zm-10 6h-4v-4h4z"></path>
                </svg>
            </div>
        )
    } else {
        return (<div></div>)
    }

}