import { HelperConfig } from './../helper_config/helper_config';
import { HelperImages } from './../helper_images/helper_images';
import { HelperSegmentation } from './helper_segmentation';

import { getSpline } from './../../modules/spline_module';
import { getEpiPredictionFromEndo } from './../../modules/prediction_module';
import { euclideanDistance } from './../../modules/math_module';
import { relocateEpiPoints } from './../../modules/geometry_module';

import { findWhere, findIndex } from 'underscore';
import { Point, LineSegment, Line } from 'ts-2d-geometry';

export class HelperSegmentationBMode {

    // INIT METHODS --------------------------

    static init(image, state) {
        let current_cycle = 0;
        let current_event = image.dicom.metadata.number_of_frames > 0? "end_diastole" : false;
        let pattern = HelperConfig.getDefaultPattern(image.type, image.modality);
        if (current_event && image.segmentation[current_cycle]?.[current_event]?.lines[0]?.pattern) {
            pattern = image.segmentation[current_cycle][current_event].lines[0].pattern;
        } else if (!current_event && image.segmentation[current_cycle]?.lines[0]?.pattern) {
            pattern = image.segmentation[current_cycle].lines[0].pattern;
        }
        const shapes = HelperConfig.getShapes(image.type, pattern, false);
        const lines = HelperConfig.getLines(image.type, pattern, false);
        const cps = HelperConfig.getControlPoints(image.type, pattern);
        if (shapes.length > 0) {
            if (image.dicom.metadata.number_of_frames > 0) {
                if (image.segmentation.length === 0) {
                    image.cardiac_events.map(cc => {
                        const segmentation_lines = [];
                        lines.map(line => {
                            const shape = shapes.filter(s => line.includes(s.name));
                            segmentation_lines.push({
                                name: line,
                                finished: false,
                                pattern: pattern,
                                shape: shape[0].name,
                                points: []
                            });
                        });
                        image.segmentation.push({
                            end_diastole: { lines: JSON.parse(JSON.stringify(segmentation_lines)) },
                            end_systole: { lines: JSON.parse(JSON.stringify(segmentation_lines)) }
                        });
                    });
                } else {
                    image.segmentation.map(cc => {
                        cc.end_diastole.lines.map(line => line.pattern = pattern);
                        cc.end_systole.lines.map(line => line.pattern = pattern);
                    });
                }
                // } else if (!image.is_segmented) {
                //     //--- REVIEW how to act when some time events are done but not all
                // }
                return HelperSegmentation.initTimeEvent(image, current_cycle, current_event);
            } else {
                let current_action = "finished";
                if (!image.is_segmented) {
                    const segmentation_lines = [];
                    lines.map(line => {
                        const shape = shapes.filter(s => line.includes(s.name));
                        segmentation_lines.push({
                            name: line,
                            finished: false,
                            pattern: pattern,
                            shape: shape[0].name,
                            points: []
                        });
                    });
                    if (segmentation_lines.length > 0) {
                        image.segmentation = [ { lines: segmentation_lines } ];
                        current_action = "segmenting";
                    } else {
                        current_action = "unactive";
                    }
                } else {
                    image.segmentation.map(cc => {
                        cc.lines.map(line => line.pattern = pattern);
                    });
                }
                const shape = shapes.filter(s => lines[0].includes(s.name));
                return { 
                    current_action,
                    current_cycle,
                    current_event,
                    current_line: lines[0],
                    current_shape: shape[0],
                    control_points: cps,
                    current_cp: cps[0]
                };
            }
        } else {
            return { 
                current_action: "caliper",
                current_cycle,
                current_event,
                caliper_tool: { points: [], dists: [], angles: [] },
                control_points: [],
                current_cp: false
            };
        }
    }

    // SEGMENTATION METHODS --------------------------

    static definePointsOfInterest(line, shape, lines, image_type) {
        const shapes = HelperConfig.getShapes(image_type, "normal", false);
        for (let point of shape.points_of_interest) {
            let already_segmented = findWhere(line, { type: point.name });
            if (!already_segmented) {
                let index = false;
                switch (point.point) {
                    case "first": index = 0; break;
                    case "last": index = line.length - 1; break;
                    default: index = point.point; break;
                }
                line[index].type = point.name;
                // line[index].show_label_in_image = false;
                already_segmented = line[index];
            }
            for (let other_shape of shapes) {
                if (other_shape.name !== shape.name) {
                    if (other_shape.points_of_interest.some(p => p.name === point.name)) {
                        for (let other_line of lines) {
                            const poi = findWhere(other_line.points, { type: point.name });
                            if (other_line.shape === other_shape.name && !poi) {
                                let new_point = JSON.parse(JSON.stringify(already_segmented));
                                new_point.id = other_line.points.length;
                                other_line.points.push(new_point);
                            }
                        }
                    }
                }
            }
        }
    }

    static checkPositionPointsOfInterest(line, points) {
        for (let i in line) {
            if (!line[i].type.includes("user-defined")) {
                const poi = findWhere(points, { name: line[i].type });
                if (poi) {
                    if (poi.point === "first" && i !== 0) {
                        line.unshift(line[i]);
                        line.splice(i+1,1);
                    } else if (poi.point === "last" && i !== line.length-1) {
                        line.push(line[i]);
                        line.splice(i,1);
                    }
                }
            }
        }
    }

    static predictFromPreviousCC(image, cycle, event) {
        image.segmentation[cycle][event].lines = JSON.parse(JSON.stringify(image.segmentation[cycle-1][event].lines));
    }

    static predictEpiFromEndo(image, state, endo_name, type, thickness) {
        const lines = HelperSegmentation.getSegmentationLines(image, state.current_cycle, state.current_event);
        const endo = findWhere(lines, { name: endo_name });
        const epi = findWhere(lines, { name: endo.name.replace("endo","epi") });
        if (epi) {
            epi.points = getEpiPredictionFromEndo(endo.points, epi.points, type, thickness);
            epi.finished = true;
        }
    }

    static finish(image, type, state) {
        const config = HelperConfig.getToolConfig("2D","normal");
        let current_action;
        if (state.current_action === "segmenting") {
            const lines = HelperSegmentation.getSegmentationLines(image, state.current_cycle, state.current_event);
            const line = findWhere(lines, { name: state.current_line });
            const cps_completed = !(state.control_points && state.control_points.length > 0 && state.control_points.length !== line.points.length);
            if ((!state.control_points && line.points.length > 2) || cps_completed) {
                line.finished = true;
                this.definePointsOfInterest(line.points, state.current_shape, lines, image.type);
                if (state.current_shape.type === "endo-epi" && line.name.includes("endo")) {
                    this.predictEpiFromEndo(image, state, line.name, "default", false);
                    current_action = "editing-thickness";
                } else if (config.auto_edit_segmentation) {
                    current_action = "editing-segmentation";
                } else {
                    current_action = "finished";
                }
            } else {
                return {};
            }
        } else {
            if (type === "task") {
                if (state.current_action === "editing-thickness") {
                    if (config.auto_edit_segmentation) {
                        current_action = "editing-segmentation";
                    } else {
                        current_action = "finished";
                    }
                } else {
                    return HelperSegmentation.initTimeEvent(image, state.current_cycle, state.current_event, state);
                }
            } else if (type === "all") {
                current_action = "finished";
            }
        }
        if (current_action === "finished") {
            return HelperSegmentation.initTimeEvent(image, state.current_cycle, state.current_event, state);
        } else {
            return { current_action };
        }
    }

    // EDIT METHODS --------------------------

    static moveSelectedPoint(image, state) {
        const config = HelperConfig.getToolConfig("2D","normal");
        const shapes = HelperConfig.getShapes(image.type, "normal", false);
        const shape_type = shapes.filter(s => s.lines.includes(state.line_selected))[0].type;
        let selected = state.element_selected;
        const lines = HelperSegmentation.getSegmentationLines(image, state.current_cycle, state.current_event);
        let points = findWhere(lines, { name: state.line_selected }).points;
        const mouse = { x: Math.round(state.mouse.pixel.x), y: Math.round(state.mouse.pixel.y) };
        if (shape_type === "oval") {
            if (selected.id === 0) {
                points[1].x += mouse.x - selected.x;
                points[1].y += mouse.y - selected.y;
                points[2].x += mouse.x - selected.x;
                points[2].y += mouse.y - selected.y;
                selected.x = mouse.x;
                selected.y = mouse.y;
            } else if (selected.id === 1) {
                const old_center = { x: points[0].x+(points[1].x-points[0].x)/2, y: points[0].y-(points[0].y-points[1].y)/2 };
                const dist = Math.sqrt(Math.pow(points[2].x-old_center.x,2)+Math.pow(points[2].y-old_center.y,2));
                const center = { x: points[0].x+(mouse.x-points[0].x)/2, y: points[0].y-(points[0].y-mouse.y)/2 };
                const slope = -1/((points[1].y-points[0].y)/(points[1].x-points[0].x));
                if (isFinite(slope)) {
                    const c = center.y - slope*center.x;
                    const A = 1 + Math.pow(slope,2);
                    const B = 2*(slope*c-slope*center.y-center.x);
                    const C = Math.pow(center.x,2) + Math.pow(c,2) + Math.pow(center.y,2) - Math.pow(dist,2) - 2*c*center.y;
                    let sign = 1;
                    if ((mouse.x >= center.x && mouse.y < center.y) || (mouse.x < center.x && mouse.y >= center.y)) {
                        sign = -1;
                    }
                    points[2].x = -B/2/A + sign*Math.sqrt(Math.pow(B,2)-4*A*C)/2/A;
                    points[2].y = slope*points[2].x + c;
                }
                selected.x = mouse.x;
                selected.y = mouse.y;
            } else if (selected.id === 2) {
                const center = { x: points[0].x+(points[1].x-points[0].x)/2, y: points[0].y-(points[0].y-points[1].y)/2 };
                const slope = -1/((points[1].y-points[0].y)/(points[1].x-points[0].x));
                const c = center.y - slope*center.x;
                selected.y = mouse.y;
                selected.x = Math.round((selected.y-c)/slope);
            }
        } else if (shape_type === "endo-epi") {
            if (state.line_selected.includes("endo")) { // Endocardium point is moving --> translate to epicardium point
                const epi_points = findWhere(lines, { name: state.line_selected.replace("endo","epi") }).points;
                let epi_point = findWhere(epi_points, { id: selected.id });
                let distance = epi_point? euclideanDistance(selected, epi_point) : config.default_epi_thickness;
                selected.x = mouse.x;
                selected.y = mouse.y;
                this.predictEpiFromEndo(image, state, state.line_selected, "auto", { id: selected.id, distance });

            } else if (state.line_selected.includes("epi")) { // Epicardium point is moving --> restrict regarding endocardium point position
                const endo_points = findWhere(lines, { name: state.line_selected.replace("epi","endo") }).points;
                const endo_point = findWhere(endo_points, { id: selected.id });
                if (endo_point) {
                    const m = (selected.y - endo_point.y)/(selected.x - endo_point.x);
                    const b = endo_point.y - m * endo_point.x;
                    let new_x; let new_y;
                    if (Number.isFinite(m)) {
                        if (Math.abs(m) < 2) {
                            new_x = mouse.x;
                            new_y = m * new_x + b;     
                        } else {
                            new_y = mouse.y;
                            new_x = (new_y - b)/m;
                        }               
                    } else {
                        new_x = endo_point.x;
                        new_y = mouse.y;
                    }
                    const dist = Math.sqrt(Math.pow(endo_point.x-new_x,2)+Math.pow(endo_point.y-new_y,2));
                    const position_is_correct = Math.sign(endo_point.x-selected.x) === Math.sign(endo_point.x-new_x) && Math.sign(endo_point.y-selected.y) === Math.sign(endo_point.y-new_y);
                    if (dist > 5 && position_is_correct) {
                        selected.x = new_x;
                        selected.y = new_y;
                    }
                }
            }
        } else {
            selected.x = mouse.x;
            selected.y = mouse.y;
        }
        if (!selected.type.includes("user-defined") && !selected.type.includes("mid_control_point") && state.line_selected.includes("endo")) {
            const shape_selected = findWhere(lines, { name: state.line_selected }).shape;
            this.spreadChangesPointsOfInterest(image, state, selected, lines, shape_selected);
        }
    }

    static spreadChangesPointsOfInterest(image, state, selected, lines, shape) {
        for (let line of lines) {
            if (line.shape !== shape && line.name.includes("endo")) {
                const epi_line = findWhere(lines, { name: line.name.replace("endo","epi") });
                for (let point of line.points) {
                    if (point.type === selected.type) {
                        if (epi_line) {
                            const epi_point = findWhere(epi_line.points, { id: point.id });
                            const distance = euclideanDistance(point, epi_point);
                            point.x = selected.x;
                            point.y = selected.y;
                            this.predictEpiFromEndo(image, state, line.name, "auto", { id: point.id, distance });
                        } else {
                            point.x = selected.x;
                            point.y = selected.y;
                        }
                    }
                }
            }
        }
    }

    // DRAW METHODS --------------------------

    static checkMouseAllowance(image, state, element) {
        if (state.current_action === "caliper" && state.caliper_tool.template && state.caliper_tool.current_action !== "finished") {
            const points = state.caliper_tool.points;
            if (state.caliper_tool.template.constraint === "straight" && points.length > 1 && points.length < state.caliper_tool.template.points.length) {
                const mouse = { x: Math.round(state.mouse.pixel.x), y: Math.round(state.mouse.pixel.y) };
                const mouse_allowed = { x: Math.round(state.mouse.pixel.x), y: Math.round(state.mouse.pixel.y) };
                const first_point = points[0].pixel;
                const last_point = points[points.length-1].pixel;
                const m = (last_point.y - first_point.y)/(last_point.x - first_point.x);
                const b = first_point.y - m * first_point.x;
                let new_x; let new_y;
                if (Number.isFinite(m)) {
                    if (Math.abs(m) < 2) {
                        new_x = mouse.x;
                        new_y = m * new_x + b;     
                    } else {
                        new_y = mouse.y;
                        new_x = (new_y - b)/m;
                    }               
                } else {
                    new_x = first_point.x;
                    new_y = mouse.y;
                }
                const dist = Math.sqrt(Math.pow(last_point.x-new_x,2)+Math.pow(last_point.y-new_y,2));
                const position_is_correct = Math.sign(first_point.x-last_point.x) === Math.sign(last_point.x-new_x) && Math.sign(first_point.y-last_point.y) === Math.sign(last_point.y-new_y);
                if (dist > 5 && position_is_correct) {
                    mouse_allowed.x = new_x;
                    mouse_allowed.y = new_y;
                    const mouse_allowed_canvas = HelperImages.pixelToCanvas(element, { x: mouse_allowed.x, y: mouse_allowed.y });
                    return { mouse_allowed: { pixel: mouse_allowed, canvas: mouse_allowed_canvas }};    
                } else {
                    return { mouse_allowed: state.mouse_allowed };
                }
            } else if (state.caliper_tool.template.constraint === "perpendicular" && points.length > 2 && points.length < state.caliper_tool.template.points.length) {
                const first_line = LineSegment.fromValues(points[0].pixel.x, points[0].pixel.y, points[1].pixel.x, points[1].pixel.y).asVector().normed();
                const p3 = new Point(points[2].pixel.x, points[2].pixel.y);
                const ortho = new Line(p3, first_line.clockwisePerpendicular());
                const mouse = new Point(Math.round(state.mouse.pixel.x), Math.round(state.mouse.pixel.y));
                const projection = ortho.project(mouse);
                const mouse_allowed = { x: projection.x, y: projection.y };
                const mouse_allowed_canvas = HelperImages.pixelToCanvas(element, { x: mouse_allowed.x, y: mouse_allowed.y });
                return { mouse_allowed: { pixel: mouse_allowed, canvas: mouse_allowed_canvas }};    
            }
        }
        return { mouse_allowed: false };
    }

    static drawSplines(image_type, mouse, finished, lines, context, element) {
        const colors = HelperConfig.getStyle("segmentation", "line_colors");
        const shapes = HelperConfig.getShapes(image_type, "normal", false);
        for (const line of lines) {
            const color = colors[findIndex(lines, { name: line.name })];
            const shape = findWhere(shapes, { name: line.shape });
            if (line.points.length > 0 && shape.type !== "nospline") {
                const canvas = [];
                for (const point of line.points) {
                    canvas.push(HelperImages.pixelToCanvas(element, { x: point.x, y: point.y }));
                }
                if (shape.type === "oval") {
                    const start = canvas[0];
                    const end = canvas.length === 1? mouse : canvas[1];
                    const distance = Math.sqrt(Math.pow(end.x-start.x,2)+Math.pow(end.y-start.y,2));
                    const center = { x: start.x+(end.x-start.x)/2, y: start.y-(start.y-end.y)/2 };
                    let ratio = HelperConfig.getToolConfig("2D", "oval")[shape.name].ratio;
                    if (canvas.length > 1) {
                        const from = canvas.length === 2? mouse : canvas[2];
                        const distance_2 = Math.sqrt(Math.pow(from.x-center.x,2)+Math.pow(from.y-center.y,2));
                        ratio = 2*distance_2/distance;
                    }
                    this.drawBModeOval(context, distance, center, Math.atan2(end.y-start.y,end.x-start.x), ratio, color, finished);
                } else {
                    let spline = getSpline(canvas, "cardinal");
                    if (finished) {
                        if (shape.type === "endo-epi" && line.name.includes("epi")) {
                            const epi_line = findWhere(lines, { name: line.name.replace("epi", "endo") });
                            if (epi_line?.points.length > 0) {
                                const epi_canvas = [];
                                for (const point of epi_line.points) {
                                    epi_canvas.push(HelperImages.pixelToCanvas(element, { x: point.x, y: point.y }));
                                }
                                const epi_spline = getSpline(epi_canvas, "cardinal");
                                spline = spline.concat(epi_spline.reverse());
                            }
                        }
                        spline.push(spline[0]);
                    }
                    this.drawBModeSpline(context, spline, color, finished);
                }
            }
        }            
    }

    static drawBModeOval(context, distance, center, slope, ratio, color, fill) {
        const style = HelperConfig.getStyle("segmentation", "spline");
        context.beginPath();
        context.ellipse(center.x, center.y, distance/2, distance*ratio/2, slope, 0, 2 * Math.PI);
        context.lineWidth = style.width;
        context.fillStyle = color;
        context.strokeStyle = color;
        context.stroke();
        if (fill) {
            context.globalAlpha = style.opacity;
            context.fill();
            context.globalAlpha = 1;
        }
        context.restore();
    }

    static drawBModeSpline(context, spline, color, fill) {
        const style = HelperConfig.getStyle("segmentation", "spline");
        context.lineWidth = style.width;
        context.fillStyle = color;
        context.strokeStyle = color;
        context.beginPath();
        for (const i in spline) {
            if (i === 0) {
                context.moveTo(spline[i].x, spline[i].y);
            } else {
                context.lineTo(spline[i].x, spline[i].y);
            }
        }
        context.stroke();
        if (fill) {
            context.globalAlpha = style.opacity;
            context.fill();
            context.globalAlpha = 1;
        }
    }


    // // REVIEW --- Not used now
    // static drawBModeRingJoint(eventData, ring_points, color_type) {
    //     let color = "#00FF00";
    //     if (color_type === 1) {
    //         color = "#F44336";
    //     } else if (color_type === 2) {
    //         color = "#2196F3";
    //     } else if (color_type === 3) {
    //         color = "#4CAF50";
    //     } else if (color_type === 4) {
    //         color = "#FF5722";
    //     }
        
    //     var allLines = [];
    //     for (let i=0 ; i<ring_points.length-1 ; i++) {
    //         allLines.push({
    //             x_start: ring_points[i].x,
    //             y_start: ring_points[i].y,
    //             x_end: ring_points[i+1].x,
    //             y_end: ring_points[i+1].y,
    //             dashed: false,
    //             color: color
    //         });
    //     }
    
    //     var context = eventData.canvasContext.canvas.getContext('2d');
    
    //     for (var i = 0; i < allLines.length; i++) {
    //         context.beginPath();
    //         context.strokeStyle = allLines[i].color;
    //         context.lineWidth = 1.2;
    //         if (allLines[i].dashed) {
    //             context.setLineDash([5, 5]);
    //         }
    //         context.moveTo(allLines[i].x_start, allLines[i].y_start);
    //         context.lineTo(allLines[i].x_end, allLines[i].y_end);
    //         context.stroke();
    //         context.setLineDash([]);
    //     }
    // }

    // VALIDATION METHODS --------------------------

    static correctAIBModeSegmentation(images) {
        for (const image of images.analysis_images) {
            if (image.modality === "2D" && image.cardiac_events.length > 0) {
                for (const cycle of image.segmentation) {
                    for (const event of Object.keys(cycle)) {
                        let epi = findWhere(cycle[event].lines, { name: "LV epi" });
                        if (epi) {
                            let endo = findWhere(cycle[event].lines, { name: "LV endo" });
                            if (endo.points[0].type.includes("free")) {
                                endo.points.reverse();
                                endo.points = endo.points.map((p,i) => { p.id = i; p.type = p.type.includes("user") ? "user-defined_"+i : p.type; return p; });
                            }
                            let correction = relocateEpiPoints(epi.points, endo.points);
                            epi.points = correction.new_epi;
                            endo.points = correction.new_endo;
                        }
                        let la = findWhere(cycle[event].lines, { name: "LA endo" });
                        let endo = findWhere(cycle[event].lines, { name: "LV endo" });
                        if (la && endo && la.points.length > 1 && endo.points.length > 1) {
                            let endo_ring = findWhere(endo.points, { type: la.points[0].type });
                            la.points[0].x = endo_ring.x;
                            la.points[0].y = endo_ring.y;
                            endo_ring = findWhere(endo.points, { type: la.points[la.points.length-1].type });
                            la.points[la.points.length-1].x = endo_ring.x;
                            la.points[la.points.length-1].y = endo_ring.y;
                        }
                    }
                }
            }
        }
    }

}