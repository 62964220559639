// TO-DO: re-structure and clean code


import React from 'react';

// Helpers
import { HelperConfig } from './../../../helpers/helper_config/helper_config';

// Components
import { PlotComponent } from './../visual_analytics_component/plot_component';

// Actions
import { onActionToPerform } from './../../../actions/actions';

import { isValueInRange, meanValue, roundValue } from './../../../modules/math_module';

let showPlot = {
    "Volume(t)": false,
    "Thickness": false
};
let possiblePlots = ["Volume(t)", "Thickness"]

const convertCaliperMeasurements = (measures) => {
    let measures2render = [];
    let measure_names = [];
    if (Array.isArray(measures)) {
        measures.map(m => {
            let var_name = m.angles? "angles" : "dists";
            m[var_name+"_name"].map((m_name, i) => {
                if (m_name) {
                    let name = m.event === "end_systole"? m_name + " (ES)" : m.event === "end_diastole"? m_name + " (ED)" : m_name;
                    let idx = measure_names.indexOf(name);
                    if (idx >= 0) {
                        measures2render[idx].value.push(m[var_name][i]);
                    } else {
                        measures2render.push({
                            name,
                            value: [m[var_name][i]],
                            units: "cm"
                        });
                        measure_names.push(name);
                    }
                }
            });
        });    
    }
    return measures2render;
}

export const MeasurementsBox = ({
        image,
        zero_line,
        mode
    }) => {
    
    const [rerender, update] = React.useState(false);

    onActionToPerform("GENERAL", { action: "DRAG-ELEMENT", value: document.getElementById("draggable-box") }, this);
    
    let measures2render = mode === "caliper"? convertCaliperMeasurements(image.measurements_caliper) : image.measurements;

    const getValueColor = (value, range) => {
        const check = HelperConfig.getMeasurementsPreference("check_normal_range");
        if (check) {
            const vir = isValueInRange(value, range);
            if (vir) {
                return HelperConfig.getMeasurementsPreference("in_range_color");
            } else if (vir === undefined) {
                return HelperConfig.getMeasurementsPreference("undefined_color");
            }
            return HelperConfig.getMeasurementsPreference("out_range_color");
        } else {
            return HelperConfig.getMeasurementsPreference("in_range_color");
        }
    }

    // RENDER METHODS
    const renderComma = (i, N) => {
        if (i < N - 1) {
            return (
                <div className="grid-block" style={{ overflow: "hidden" }}>, </div>
            )
        }
    }
    const showPlotFunc = (plot_type) => {
        showPlot[plot_type] = !showPlot[plot_type];
        update(plot_type + "_" + String(showPlot[plot_type]));
    }
    const renderMeanValue = (mean, mean_color, N) => {
        if (N > 1) {
            return (
                <div className="grid-block horizontal">
                    <div className="grid-block" style={{ overflow: "hidden", color: "white", paddingLeft: "2px" }}> (</div>
                    <div className="grid-block" style={{ overflow: "hidden", color: mean_color }}>{mean}</div>
                    <div className="grid-block" style={{ overflow: "hidden", color: "white" }}>) </div>
                </div>
            )
        }
    }
    const renderMeasure = (values, name, units, range) => {
        const abs = HelperConfig.getMeasurementsPreference("absolute_values");
        const values_to_show = abs.includes(units)? values.map(v => Math.abs(v)) : values;
        const mean = meanValue(values_to_show);
        const mean_color = getValueColor(mean, range);
        if (values.length > 0) {
            return (
                <div key={name} className="grid-block horizontal " style={{ overflow: "hidden", padding: "7px" }}>
                    <div style={{ overflow: "hidden", fontWeight: "bold", paddingRight: "2px", marginRight: "2px" }}>{name}: </div>
                    {values_to_show.map((value, i) => {
                        let color = getValueColor(roundValue(value), range);
                        return (
                            <div key={i} className="grid-block horizontal shrink" style={{ overflow: "hidden" }}>
                                <div className="grid-block shrink" style={{ overflow: "hidden", color: color, paddingLeft: "2px" }}>{roundValue(value)}</div>
                                {renderComma(i, values_to_show.length)}
                            </div>
                        )
                    })}
                    {renderMeanValue(mean, mean_color, values_to_show.length)}
                    <div style={{ overflow: "hidden", paddingRight: "2px", fontStyle: "italic" }}>{units}</div>
                </div>
            );
        }
    }
    const renderPlot = (plot_type) => {
        if (showPlot[plot_type]) {
            return (
                <PlotComponent
                    measures={measures2render}
                    image={image}
                    plot_type={plot_type}
                />
            )
        }
    }
    const renderPlots = () => {

        return (possiblePlots.map((plot_type) => {
            let fillcolor = "#eb4034";
            if (showPlot[plot_type]) {
                fillcolor = "grey";
            }
            return (
                <div key={plot_type} className="grid-block horizontal" style={{ display: "flex", flexDirection: "row", alignContent: "center", alignItems: "center" }}>
                    <div style={{ fontWeight: "bold" }}>{plot_type}</div>
                    <div style={{ padding: "10px", top: "-17px" }} onClick={() => { showPlotFunc(plot_type) }}>
                        <svg style={{ width: 35, height: 35, fill: fillcolor, "&:hover": { fill: "white" } }} viewBox="0 0 192 192">
                            <g id="_11-graphs" data-name="11-graphs">
                                <path d="m16 176v-136h-16v144a8 8 0 0 0 8 8h184v-16z" />
                                <path d="m26.343 122.343 11.314 11.314 6.533-6.533 12.5 28.125a8.08 8.08 0 0 0 11.749 3.407l18.541-12.362 19.363 19.363a8.079 8.079 0 0 0 13.176-2.923l11.381-31.307 6.587 9.223a8.077 8.077 0 0 0 13.793-1.339l17.08-37.576 10.64 8.512 10-12.494-13.8-11.044 16.09-35.4-14.566-6.622-14.424 31.713-13.3-10.647a8.081 8.081 0 0 0 -12.517 3.513l-10.229 28.126-15.744-22.042a8.077 8.077 0 0 0 -13.665 1.072l-11.945 23.895-16.462-10.973a8 8 0 0 0 -10.095 1l-8.994 8.995-10.038-22.588-14.622 6.5 12.538 28.21zm121.365-37.132 7.766 6.213-13.021 28.646-4.917-6.884zm-82.688 21.083 18.543 12.362a8 8 0 0 0 11.592-3.078l10.058-20.116 14.408 20.171-10.847 29.828-15.117-15.118a8 8 0 0 0 -10.094-1l-16.106 10.738-11.146-25.081z" /></g></svg>
                    </div>
                    {renderPlot(plot_type)}
                </div>
            )

        })
        )
    }
    const renderMeasurements = () => {
        if (image.modality !== "2D" || image.cardiac_events.length === 0) {
            return (
                measures2render.map((measure) => {
                    if (measure.type !== "plot") {
                        return (renderMeasure(measure.value, measure.name, measure.units, measure.normal_range))
                    }
                }))
        }
        else {
            return (
                <div>
                    {/* <div style={{ marginBottom: "10px", top: "-30px" }}>
                        {renderPlots()}
                    </div> */}
                    {measures2render.map((measure) => {
                        if (measure.type !== "plot") {
                            return (renderMeasure(measure.value, measure.name, measure.units, measure.normal_range))
                        }
                    })}
                </div>

            )
        }
    }

    if (measures2render.length > 0 && measures2render.some(m => m.value.length > 0)) {
        return (
            <div className="grid-block vertical" id="draggable-box">
                {renderMeasurements()}
            </div>
        )
    }
    else {
        return (<div></div>)
    }
}