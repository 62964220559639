import React from 'react';
// Components
import { ProjectPicker } from '../project_picker_component/project_picker_component';
import { PageName } from '../page_name/page_name';
import { StatisticsComponent } from './../statistics_component/statistics_component';
import { DBStatistics } from './db_statistics';
import { DatabaseTable } from './db_table';
import { DatabaseQuery } from './query_component';
// Styles
import { useStyles } from './db_styles';

export function DatabaseComponent(props) {

    const classes = useStyles();

    const renderContent = () => {
        if (props.from === "studies" && props.studies_tab === "stats") {
            return (
                <StatisticsComponent project={props.currentProject} />
            );
        } else {
            return (
                <>
                    <DatabaseQuery
                        items={props.items}
                        onactiontoperform={props.onactiontoperform}
                        from={props.from}
                        hide_completed={props.hide_completed}
                        on_change_hide={props.on_change_hide}
                        search_method={props.search_method}
                        on_change_search_method={props.on_change_search_method}
                    />
                    <DatabaseTable
                        items={props.items}
                        onactiontoperform={props.onactiontoperform}
                        from={props.from}
                        page={props.page}
                        initial_page={props.initial_page}
                        sort_by={props.sort_by}
                        sort_direction={props.sort_direction}
                    />
                </>
            );
        }
    }

    return (
        <div className={"grid-block vertical " + classes.root} >
            <div className="grid-block horizontal shrink ">
                <PageName site={props.site} />
                <ProjectPicker
                    projects={props.projects}
                    onactiontoperform={props.onactiontoperform}
                    currentProject={props.currentProject}
                    from={props.from}
                    page={props.page} />
                <DBStatistics
                    items={props.items}
                />
            </div>
            {renderContent()}
        </div>
    );
}