import {
    DataProvider
} from "../../dataprovider/dataprovider";
import {
    HelperStudies
} from "./../helper_studies/helper_studies";
import {
    DICOM_loader
} from "../../loaders/DICOM_loader/DICOM_loader";
import { 
    getImageMetadataFromDicom
} from "../../modules/dicom_module";
import {
    findWhere
} from 'underscore';
import {
    data_provider_api
} from './../../dataprovider/old_dataprovider_api';

export class HelperImagesCloud {
    static downloadDICOMFromURL(urls, user_token) {
        let blob_promises = [];
        urls.map((presigned_url) => {
            blob_promises.push(fetch(presigned_url)
                .then(res => res.blob()) 
                .then(blob => {
                    return blob
                }).catch( (response) =>{
                    console.log(response.status, response.statusText);
                }));
        });
        return Promise.all(blob_promises).then((blobs) => {
            let images = [];
            blobs.map((blob) => {
                if(blob){
                    images.push(DICOM_loader.fromBlob(blob));
                } else{
                    console.log("NO blob")
                }
            });
            return Promise.all(images).then((dicoms) => {
                let study_images = HelperStudies.getStudyImages();
                dicoms.map(dicom => {
                    let loaded = findWhere(study_images.loaded_images, {
                        instance_id: dicom.instance_id
                    });
                    let analysis = findWhere(study_images.analysis_images, {
                        id: dicom.instance_id
                    });
                    delete dicom.instance_id;
                    if (loaded) {
                        loaded.dicom = dicom;
                    }
                    if (analysis) {
                        analysis.dicom = dicom;
                        analysis.metadata = getImageMetadataFromDicom(dicom.metadata, analysis.modality);
                    }
                });
                HelperStudies.setStudyImages(study_images);
                return dicoms;
            });
        });
    }
    static createDICOMsnapshot(bucket, patient, study_date, filename, frame, dicom_folder, user_token) {
        return DataProvider("MINIO", "CREATE_DICOM_SNAPSHOT", {
            bucket, patient, study_date, filename, frame, dicom_folder
        }, user_token);
    }
}