import {
    findWhere,
    findIndex,
    find
} from 'underscore';

import {
    HelperUserLocalStorage
} from "./helper_user_localstorage";
import {
    HelperUserStore
} from "./helper_user_store";
import {
    HelperUserBackend
} from './helper_user_backend';

import config_user_roles from './../../config/config_user_roles.json'
export class HelperUser {
    static validateUserAuthentication() {
        let isValid = true;
        if (!this.isTokenSaved()) {
            isValid = false;
        } else {
            var localData = localStorage.getItem("createdAt");
            if (localData !== undefined) {
                var localData = new Date(localData);
                var today = new Date();
                if (localData.getFullYear() === today.getFullYear()) {
                    if (localData.getMonth() === today.getMonth()) {
                        if (localData.getDate() <= today.getDate() - 7) {
                            isValid = false
                        }
                    } else {
                        isValid = false
                    }
                } else {
                    isValid = false
                }
            }
        }
        return isValid;
    }
    static isTokenSaved() {
        return localStorage.getItem("token")
    }
    static setUser() {
        const urlParams = new URLSearchParams(window.location.search);
        const family_names = urlParams.get('family_names');
        const token = urlParams.get('token');
        const name = urlParams.get('name');
        const email = urlParams.get('email');
        let date = new Date();
        HelperUserStore.setUser(token, name, family_names, email)
        HelperUserLocalStorage.setUser(token, name, family_names, email, date)

    }
    static login() {
        window.location = "http://api-auth-test.bcn-medtech-apps.upf.edu/auth?url=" + window.location.origin;
    }
    static getRoleFromConfig(rol) {
        let config = JSON.parse(JSON.stringify(config_user_roles));
        let all_roles = config.roles;
        return findWhere(all_roles, {
            type: rol
        })
    }
    static setUserType(rol) {
        HelperUserStore.setUserType(rol);
        HelperUserLocalStorage.setUserType(rol);
    }
    static getUserType() {
        return HelperUserLocalStorage.getUserType();
    }
    static setUserRoles(roles) {
        HelperUserLocalStorage.setUserRoles(roles);
    }
    static getUserRoles() {
        return HelperUserLocalStorage.getUserRoles();
    }
    static getUserRolesFromDB() {
        let user = this.getUser();
        return HelperUserBackend.getUserRolesFromDB(user.user_email, user.user_token).then((user) => {
            return user;
        })
    }
    static getUser() {
        let userFromStore = HelperUserStore.getUser();
        if (!userFromStore.user_email) {
            userFromStore = HelperUserLocalStorage.getUser()
        }
        return userFromStore;
    }
    static removeUser() {
        HelperUserStore.removeUser();
        HelperUserLocalStorage.removeUser()
    }
    static getUserProjects() {
        let user = this.getUser();
        return HelperUserBackend.getUserProjects(user.user_email, user.user_token);
    }
    static getName2Show(user) {
        let string = user.user_name;

        let user_family_names = user.user_family_names.split(" ");
        user_family_names.forEach(family_name => {
            let family_name_lower_case = family_name.toLowerCase();
            let nameCapitalized = family_name_lower_case.charAt(0).toUpperCase() + family_name_lower_case.slice(1)
            string = string + " " + nameCapitalized;
        });
        return string
    }
    static getNameAbreviation (user) {
        let user_name_abr;
        if (user.user_name) {
            user_name_abr = user.user_name.charAt(0);
            let user_family_names = user.user_family_names.split(" ");

            user_family_names.forEach(family_name => {
                user_name_abr = user_name_abr + family_name.charAt(0);
            });

            return user_name_abr;
        }
    }
    static getCurrentVersionTermsConditions() {
        return "20240627";
    }
    static checkUserAcceptedTermsConditions() {
        let current_version = this.getCurrentVersionTermsConditions();
        return this.getUserRolesFromDB().then(user => {
            return user.accepted_terms_of_use && user.accepted_terms_of_use[current_version];
        });
    }
    static acceptTermsConditions() {
        let user = this.getUser();
        let version = this.getCurrentVersionTermsConditions();
        return HelperUserBackend.acceptTermsConditions(user.user_email, version, user.user_token);
    }
    static openAnotherPlatform(redirect2page) {
        let user = this.getUser();
        let url = redirect2page + "?token=" + user.user_token + "&name=" + user.user_name + "&family_names=" + user.user_family_names + "&email=" + user.user_email;
        window.open(url, '_blank').focus();
    }
}